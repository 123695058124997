<template>
  <div class="d-flex">
    <div style="width: 50vw;">
      <b-card style=" height: 100vh; background-color: rgba(0,0,0,0.5)">
        <div @click="$router.push('/')" style="font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
          Mortgage Envy
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center full-height">
          <div
            style="width: 20vw; font-size: 40px;color: white; font-weight: bolder;"
            class="text-center"
          >First, are you
            buying?</div>
          <hwa-button
            label="See Rates"
            @onClick="startProcess('purchase')"
          />
        </div>
      </b-card>
    </div>
    <div style="width: 50vw;">
      <div class="d-flex flex-column align-items-center justify-content-center full-height">
        <div
          style="width: 20vw; font-size: 40px;color: white; font-weight: bolder;"
          class="text-center"
        >Or refinancing
          a home?</div>
        <hwa-button
          label="See Rates"
          @onClick="startProcess('refinance')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'

export default {
  name: 'GetStarted',
  components: {
    HwaButton,
    BCard,
  },
  mounted() {
    this.createAnonymousUser()
  },
  methods: {
    startProcess(type) {
      this.$store.state.auth.getStartedCompleted = true
      let route = 'step-1'
      if(type==='refinance'){
        route = 'r-step-1'
      }
      this.$router.push({ name: route })
    },
    createAnonymousUser() {
      this.$http.post('api/new_customer').then(res => {
        console.log(res.data)
        localStorage.setItem('anonymous', res.data.anonymous_id)
      })
    },
  },
}
</script>

<style scoped>

</style>
